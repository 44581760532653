@import "../../../../scss/index.scss";

.aa{
    width: 10%;
    font-size: 15px;
    padding-left: 0px;        
}

.bb{
    width: 90%;
    >input{
        background-color: $background-secondary !important;        
    }
}

.grid {
    width: 100%;
    display: flex;
    flex-wrap: wrap;    

    > div {
      width: 20%;
  
      padding: 5px;
  
      > img {
        min-height: 10vh;
        max-height: 10vh;
        min-width: 10vh;        
        max-width: 10vh;
        border-radius: 6px;
        width: 100%;        
      }
    }
}