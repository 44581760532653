@import "../../../scss/index.scss";

.Schema{
    padding: 20px 20px 20px 20px;
    background-color: rgb(243, 243, 243);
}

.SchemaPage{
    .ui.segment.tab{
        height: calc(100vh - 200px);
        overflow-y: scroll;
        &::-webkit-scrollbar{display: none;}
    }
}

.SchemaPageAdd{
    display: flex;
    justify-content: flex-end;
    margin-bottom: 10px;
    > .ui.button {
        margin: 0;
    }
}

.SchemaTitleLeft{
    display: flex;
    justify-content: left;
    width: 66.66%;
    color: $text-secondary;
    >h1{
        font-size: 25px;
        color: $text-secondary;
    }
}

.SchemaTitleRight{
    width: 33.33%;
    display: flex;
    justify-content: right;
    align-items: end ;
}