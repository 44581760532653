@import "../../../scss//index.scss";

.barTrust {
  width: 100%;
  background-color: $background-tertiary;
}

.content {
  display: flex !important;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 40px 0;
}

.block {
  display: flex;
  align-items: center;
  padding: 0 20px;
  border-right: 1px solid $border-primary;
  //font-size: 20px;

  &:last-of-type {
    border: 0;
  }

  i {
    padding-top: 5px;
    font-size: 35px !important;
    margin-right: 15px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $primary;
  }

  h5 {
    font-size: 20px;
  }

  span {
    font-size: 13px;
    color: $text-secondary;
  }
}
