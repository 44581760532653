.signUp {
    width: 75%;
  
    > h3 {
      margin-bottom: 20px;
    }
  
    .actions {
      margin-top: 10px;
    }
  }