.ui.button.primary {
    background-color: $primary;
    color: $text-primary;
    margin: 0;
    padding: 15.5px 30px;
    border-radius: 6px;
    margin: 0;
  
    &:hover,
    &:active,
    &:focus {
      background-color: $primary-hover;
    }
  }