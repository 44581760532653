.gallery {
    display: flex;
  }
  
  .principal {    
    width: 50%;
    padding-right: 10px;
  
    > img {
      max-height: 55vh;
      min-height: 55vh;
      border-radius: 16px;
      width: 100%;
      height: 100%;
      object-fit: cover;
  
      &:hover {
        opacity: 0.6;
        cursor: pointer;
      }
    }
  }

  .principalMT {     
    width: 50%;
    padding-right: 10px;
  
    > img {
      max-height: 25vh;
      min-height: 25vh;
      border-radius: 16px;
      width: 100%;
      object-fit: cover;
  
      &:hover {
        opacity: 0.6;
        cursor: pointer;
      }
    }
  }
  
  .grid {
    width: 50%;
    display: flex;
    flex-wrap: wrap;
    padding-left: 10px;
  
    > div {        
      width: 33.33%;
       
      &:nth-child(1) {
        padding: 10px 10px 10px 10px;
      }
      &:nth-child(2) {
        padding: 10px 10px 10px 10px;
      }
      &:nth-child(3) {
        padding: 10px 10px 10px 10px;
      }
      &:nth-child(4) {
        padding: 10px 10px 10px 10px;
      }
      &:nth-child(5) {
        padding: 10px 10px 10px 10px;
      }
      &:nth-child(6) {
        padding: 10px 10px 10px 10px;
      }
      &:nth-child(7) {
        padding: 10px 10px 10px 10px;
      }
      &:nth-child(8) {
        padding: 10px 10px 10px 10px;
      }
      &:nth-child(9) {
        padding: 10px 10px 10px 10px;
      }
      &:nth-child(10) {
        padding: 10px 10px 10px 10px;
      }
      &:nth-child(11) {
        padding: 10px 10px 10px 10px;
      }
  
      > img {
        min-height: 25vh;
        max-height: 25vh;        
        object-fit: cover;
        border-radius: 16px;        
        width: 100%;
          
        &:hover {
          opacity: 0.6;
          cursor: pointer;
        }
      }
    }
  }

  .gridTM {
    width: 50%;
    display: flex;
    flex-wrap: wrap;
    padding-left: 10px;

    > div {
      width: 50%;
       
      &:nth-child(1) {
        padding: 10px 10px 10px 10px;
      }
      &:nth-child(2) {
        padding: 10px 10px 10px 10px;
      }
      &:nth-child(3) {
        padding: 10px 10px 10px 10px;
      }
      &:nth-child(4) {
        padding: 10px 10px 10px 10px;
      }
      &:nth-child(5) {
        padding: 10px 10px 10px 10px;
      }
      &:nth-child(6) {
        padding: 10px 10px 10px 10px;
      }
      &:nth-child(7) {
        padding: 10px 10px 10px 10px;
      }
      &:nth-child(8) {
        padding: 10px 10px 10px 10px;
      }
      &:nth-child(9) {
        padding: 10px 10px 10px 10px;
      }
      &:nth-child(10) {
        padding: 10px 10px 10px 10px;
      }
      &:nth-child(11) {
        padding: 10px 10px 10px 10px;
      }
  
      > img {
        min-height: 10vh;
        max-height: 10vh;                
        object-fit: cover;
        border-radius: 16px;
        width: 100%;              
  
        &:hover {
          opacity: 0.6;
          cursor: pointer;
        }
      }
    }
  }
  
  .carouselContainer {
    height: 100%;
  
    > div {
      height: 100%;
  
      > div {
        width: 50%;
        margin: 0 auto;
        
        img{
          width: 100%;
          min-height: 75vh;
          max-height: 75vh;
          object-fit: cover;  
          border-radius: 16px;
          height: calc(100% - 100px);
          object-fit: cover;

        }
      }
    }
  
    .dots {
      display: flex !important;
      align-items: flex-end;
      justify-content: center;
      height: 100px;
  
      > li {
        height: 100%;
        width: 75px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 5px;
  
        img {
          width: 100%;
          min-height: 10vh;
          max-height: 10vh;
          object-fit: cover;  
          border-radius: 10px;
  
          &:hover {
            cursor: pointer;
            opacity: 0.6;
          }
        }
      }
    }
  }

  .carouselContainerMT {
    height: 100%;
  
    > div {
      height: 100%;
  
      > div {
        width: 50%;
        margin: 0 auto;
        
        img{
          width: 100%;
          min-height: 40vh;
          max-height: 40vh;
          object-fit: cover;  
          border-radius: 16px;
          height: calc(100% - 100px);
          object-fit: cover;

        }
      }
    }
  
    .dots {
      display: flex !important;
      align-items: flex-end;
      justify-content: center;
      height: 100px;
  
      > li {
        height: 100%;
        width: 75px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 5px;
  
        img {
          width: 100%;
          min-height: 5vh;
          max-height: 5vh;
          object-fit: cover;  
          border-radius: 10px;
  
          &:hover {
            cursor: pointer;
            opacity: 0.6;
          }
        }
      }
    }
  }