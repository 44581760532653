@import "../../../scss/index.scss";

.container {
  position: relative;
  
  &::after {
    content: "";
    background-color: $background-primary;
    position: absolute;
    bottom: -1px;
    height: 60px;
    width: 100%;
    clip-path: polygon(0 100%, 100% 100%, 0 0);
  }
}

.wallpaper {
  width: 100%;
  height: 100%;
  //object-fit: cover;
}

.infoContainer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;

  .date {
    font-size: 12px;
    color: $primary;
    font-weight: bold;
  }

  h2 {
    margin: 5px 0 20px 0 !important;
  }

  .price {
    display: flex;
    align-items: center;
  }

  .finalPrice {
    margin-left: 65px;
    font-size: 30px;
  }
}