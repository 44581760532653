@import "../../../scss/index.scss";

.titleAdmin{
  color: $text-secondary;
  font-size: 30px;
  padding-top: 20px;
  //padding-left: 20px;

}

.gridMenu {
    display: flex;
    flex-wrap: wrap;
    margin: 10px;
    margin-top: 20px;
    //padding-left: 20px;
  }
  
  .menu {
    width: 15%;
    margin-bottom: 10px;
    padding-right: 20px;
  
    &:hover {
      opacity: 0.6;

    }
  
    > div {
      border: 2px solid $border-primary ;
      border-radius: 10%;  

      position: relative;
      padding: 10px 10px 0 10px;
  
      &:last-of-type {
        padding-top: 0;
        display: flex;
        justify-content: space-between;
      }
  
      > img {
        width: 100%;
        border-radius: 16px;
      }
    }
  }

  .menuMT {
    width: 50%;
    margin-bottom: 10px;
    padding-right: 20px;
  
    &:hover {
      opacity: 0.6;
    }
  
    > div {
      border: 2px solid $border-primary ;
      border-radius: 10%;  

      position: relative;
      padding: 10px 10px 0 10px;
  
      &:last-of-type {
        padding-top: 0;
        display: flex;
        justify-content: space-between;
      }
  
      > img {
        width: 100%;
        border-radius: 16px;
      } 
    }
  }

  .title{
    display: flex;
    justify-content: center !important;
    >span{
      font-size: 20px;
      color: $text-secondary;

    }
  }