@import "../../../scss/index.scss";

.Schema{
    padding: 20px 20px 20px 20px;
    background-color: rgb(243, 243, 243);
}

.SchemaPage{
    .ui.segment.tab{
        height: calc(100vh - 200px);
        overflow-y: scroll;
        &::-webkit-scrollbar{display: none;}
    }
}

.SchemaPageAdd{
    width: 100%;
    >h1{
        color: $text-secondary;
    }
    margin-bottom: 10px;
    > .ui.button {
        margin: 0;
    }
}