@import "../../../scss/index.scss";

.panel {
  position: relative;
  display: flex !important;
  margin-top: -150px;
}

.panelMT{
  position: relative;
  
  margin-top: -150px;
}
.imgContiner {
  width: 50%;
  min-height: 50vh;
  max-height: 150vh;;
  padding-right: 12px;

  > img {
    border-radius: 15px;
  }
}

.imgContinerMT{
  width: 100%;  
  padding-bottom: 20PX;
}

.actionsContainer {
  width: 50%;
  padding-left: 12px;
  height: 100%;

  > div {
    background-color: $background-quaternary__blur;
    backdrop-filter: blur(15px);
    border-radius: 15px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;

    > h2 {
      margin-bottom: 10px;
    }
  }
}

.actionsContainerMT {
  width: 100%;
  height: 100%;

  > div {
    background-color: $background-quaternary__blur;
    backdrop-filter: blur(15px);
    border-radius: 15px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    //align-items: ;

    > h2 {
      margin-bottom: 10px;
    }
  }
}

.moreInfo {
  background-color: $background-quaternary__blur;
  backdrop-filter: blur(15px);
  display: flex;
  padding: 6px 15px;
  border-radius: 50px;
  margin-bottom: 40px;
  width: 50%;

  > span {
    display: flex;
    align-items: center;
    border-right: 1px solid $border-primary;
    padding-right: 10px;
    margin-right: 10px;
    font-size: 12px;

    &:first-of-type {
      width: 50%;
      > img {
        filter: brightness(0) invert(1);
        height: 18px;
        margin-right: 10px;
      }
    }

    &:last-of-type {
      width: 50%;
      border-right: 0;
      padding-right: 0;
      margin-right: 0;

      i {
        display: flex;
        align-items: center;
        color: $success;
        margin-right: 5px;
        font-size: 14px;
      }
    }
  }
}

.moreInfoMT {
  background-color: $background-quaternary__blur;
  backdrop-filter: blur(15px);
  display: flex;
  padding: 6px 15px;
  border-radius: 50px;
  margin-bottom: 40px;  

  > span {
    display: flex;
    align-items: center;
    border-right: 1px solid $border-primary;
    padding-right: 10px;
    margin-right: 10px;
    font-size: 12px;

    &:first-of-type {
      width: 50%;
      > img {
        filter: brightness(0) invert(1);
        height: 18px;
        margin-right: 10px;
      }
    }

    &:last-of-type {
      width: 50%;
      border-right: 0;
      padding-right: 0;
      margin-right: 0;

      i {
        display: flex;
        align-items: center;
        color: $success;
        margin-right: 5px;
        font-size: 14px;
      }
    }
  }
}

.price {
  margin-bottom: 40px;

  .originalPrice {
    font-size: 18px;
    text-decoration: line-through;

    > i {
      font-size: 12px;
    }
  }

  .discount {
    margin: 0 10px;
    color: $primary;
    font-weight: bold;
    font-size: 18px;
  }

  .price {
    font-size: 35px;
  }
}

.heart {
  margin: 0 !important;
  position: absolute;
  top: 5px;
  right: 15px;
  font-size: 20px !important ;
}