@import "../../../scss/index.scss";

.account {
  display: flex;

  button {
    background-color: transparent !important;
    margin-left: 10px !important;

    &:hover {
      > i {
        color: $primary;
      }
    }

    > i {
      font-size: 18px;
      height: 20px !important;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .cart {
    display: flex;
    align-items: center;
    justify-content: center;

    > div {
      background-color: $primary;
      color: $text-primary;
      font-size: 10px;
      margin-left: 5px;
    }
  }

  .user {
    background-color: $background-tertiary !important;
    border-radius: 10px;
    border: 2px solid $background-tertiary;

    &:hover {
      border: 2px solid $primary;

      i {
        color: $text-primary;
      }
    }
  }
}