.ui.modal.visible.active {
    background-color: $background-primary;
    border-radius: 16px;    

    > .header {
      background-color: $background-primary;
      color: $text-primary;
      font-size: 20px;      
    }
  
    > .content {
      background-color: $background-primary;
      padding: 30px;
    }
  }