@import "../../../scss/index.scss";

.info {
  display: flex !important;
}

.summary {
  width: 50%;
  padding: 20px 20px 20px 0;

  p {
    color: $text-secondary;
    line-height: 25px;
  }
}

.more {
  width: 50%;
  padding: 20px 0 20px 20px;

  ul {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      padding-bottom: 5px;

      span {
        color: $text-secondary;
      }
    }
  }
}