@import "../../../../../scss/index.scss";

.UserItem{
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 2px solid #e4e4e4;
    margin-bottom: 10px;
    padding-bottom: 10px;

    &:nth-last-of-type(2){
        border-bottom: 0;
        margin-bottom: 0;
        padding-bottom: 30px;
    }

    >div{
        > span{
            color: $text-secondary;
        }

    }

    button{
        padding: 10px 10px !important;
    }    
}

.UserItemInfo{
    width: 55%;
    display: flex;
    flex-direction: column;    
}