.signIn {
    width: 50%;
  
    > h3 {
      margin-bottom: 20px;
    }
  
    .actions {
      margin-top: 10px;
    }
  }