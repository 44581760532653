@import "../../../../scss/index.scss";

.FormLeft{
    width: 10%;
    font-size: 15px;
    padding-left: 0px;        
}

.FormRight{
    width: 90%;
    >input{
        background-color: $background-secondary !important;        
    }
}