.ui.selection.dropdown.number {
    background-color: $background-tertiary;
    border-radius: 5px !important;
  
    &.visible.active {
      border-color: $primary;
    }
  
    .divider.text {
      color: $text-primary;
    }
  
    .visible.menu.transition {
      background-color: $background-secondary;
      margin-top: 10px;
      border-radius: 6px;
      border: 0;
  
      &::-webkit-scrollbar {
        display: none;
      }
  
      .item {
        border: 0;
        display: flex;
        align-items: center;
        justify-content: center;
  
        &:hover,
        &.activ {
          background-color: $primary;
        }
  
        .text {
          margin: 0;
        }
      }
    }
  }