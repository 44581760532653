@import "./index.scss";
@import "./semantic-ui/index.scss";

* {
  color: $text-primary;
}

body {
  margin: 0;
  background-color: $background-primary;
}

h1,
h2,
h3,
h4,
h5 {
  margin: 0;
}

p {
  margin: 0;
}

a {
  color: $text-primary;

  &:hover {
    color: $primary;
  }
}