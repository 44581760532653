.content {
    display: flex;
    align-items: center;
  
    > div {
      &:nth-child(1),
      &:nth-child(2) {
        width: 50%;
        margin: 0 !important;
      }
  
      &:nth-child(2) {
        margin-left: 15px !important;
      }
  
      &:last-of-type {
        margin-left: 15px !important;
      }
    }
  }