$primary                        : #881241;
$primary-hover                  : #ff1b82;

$text-primary                   : #fff;
$text-secondary                 : black;


$border-primary                 : #3d3d3d;
$border-secondary               : #474747;

$background-primary             : #dba2b3;
$background-secondary           : #1d1d1d;
$background-tertiary            : #febecc;
$background-quaternary          : #3d3d3d;
$background-quaternary__blur    : #00000010;

$success                        : #69af00;
$danger                         : #ff0a4e;

$backgroud-dark                 : #001b35;
$backgroud-grey                 : #f0f3f4;
$backgroud-dark-web             : #16212b;

$border-grey                    : #808080;
