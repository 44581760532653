.topBar {
    position: fixed;
    //display: flex;
    //align-items: center;
    width: 100%;
    //padding: 20px;
    z-index: 5;
  }
  
  .left {    
    //width: 20%;
  
    img {
      width: 200px;      
      height: 100px;
      object-fit: cover;
    }
  }
  
  .center {
    //width: 60%; 
    padding-top: 20px;   
    display: flex;
    justify-content: center;
  }
  
  .right {
    //width: 20%;
    padding-top: 25px;
    display: flex;
    justify-content: flex-end;
  }