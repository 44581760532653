@import "../../scss/index.scss";

.container {
    height          :   100vh           ;
    max-height      :   100vh           ;
    display         :   flex            ;
    flex-direction  :   row             ;
    justify-content :   space-between   ;
}

.topBar{
    position        :   absolute        ;
    top             :   10              ;
    left            :   0               ;
    width           :   100%            ;
    display         :   flex            ;
    justify-content :   space-between   ;
    padding         :   15px            ;

    > a {
        > img {
            height  :  200px            ;            
        }

        > i {
            font-size   :   26px        ;
            color       :   $danger     ;
            margin      :   0           ;
        }
    }
}

.topBarM{
    position        :   absolute        ;
    top             :   10              ;
    left            :   0               ;
    width           :   100%            ;
    display         :   flex            ;
    justify-content :   space-between   ;
    padding         :   15px            ;

    > a {
        > img {         
            padding-left: 30px;
            width: 100%;
            height: 70%;
            object-fit: cover;

        }

        > i {
            font-size   :   26px        ;
            color       :   $danger     ;
            margin      :   0           ;
        }
    }
}

.topBarT{
    position        :   absolute        ;
    top             :   10              ;
    left            :   0               ;
    width           :   100%            ;
    display         :   flex            ;
    justify-content :   space-between   ;
    padding         :   15px            ;

    > a {
        > img {
            padding-left: 30px;
            width: 100%;
            height: 60%;
            object-fit: cover;
            
        }

        > i {
            font-size   :   26px        ;
            color       :   $danger     ;
            margin      :   0           ;
        }
    }
}

.blockLeft{
    width           :   50%             ;
    display         :   flex            ;
    align-items     :   center          ;
    justify-content :   center          ;
}

.blockLeft2{
    width           :   100%            ;
    display         :   flex            ;
    align-items     :   center          ;
    justify-content :   center          ;
}

.blockRight{
    background-image: url("../../../public/images/logo5.jpeg");
    background-position: center;
    background-size: cover;
    height: 100%;
    width: 50%;

}