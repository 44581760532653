@import "../../../scss/index.scss";

.info {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 70px;
}

.user {
  background-color: $background-quaternary !important;
  border-radius: 20px !important;
  margin: 0 !important;
  cursor: auto !important;
  height: 100px;
  width: 100px;
  display: flex !important;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px !important;

  i {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 50px;
  }
}

.username {
  font-size: 30px;
  margin-bottom: 5px;
}

.email {
  font-weight: bold;
  margin-bottom: 10px;
}

.createdAt {
  color: $text-secondary;
  font-size: 12px;
}