@import "../../scss/index.scss";

$widthLeftMenu: 40px;

.AdminLayout{
    display: flex;
    max-height: 100vh;
    min-height: 100vh;
    background-color: $backgroud-grey;
}

.AdminLayoutLeft{
    background-color: $backgroud-dark;
    width: $widthLeftMenu;
}

.Logo{
    max-height: 50vh;
    display: flex;    
    height: 50px;
    width: 100%;
    padding-top: 0;
    //margin: 10px 0;
    object-fit: cover;

}

.AdminLayoutRight{
    width: 100%;
}

.AdminLayoutHeader{
    background-color: $border-grey;            
    display: flex;
    height: 75px;
}

.AdminLayoutRightHeader{
    width: 80%;
    height: 75px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0 10px;
}

.AdminLayoutLeftHeader{
    width: 20%;
    height: 75px;
    display: flex;
    align-items: center;
    padding-left: 20px;
    > a{
        >img{
            min-height: 8vh;
            max-height: 8vh;    
            object-fit: cover;

        }
    }
    
    Image{
        

    }
}

.AdminLayoutRightContent{
    position: relative;
    margin: 20px;
}